/* Header.js */

/* React */
import React from 'react';

/* Data */
import { onePng } from '../../assets';

/* Header Page */
const Header = ({ onContactUsClick }) => {

    //const waveLink = "https://raw.githubusercontent.com/patelkushal112/secureteckfiles/main/uploads/wave5.webm";

    return (

        <div>

            {/* 
            ################################################################################################
                                                xs: Phones (480px)
            ################################################################################################
            */}

            <div className='flex flex-col sm:hidden'>

                {/* Image Background */}
                <img className='absolute top-1/2 w-full h-[50dvh] object-cover -z-10' src={onePng} alt="" />
                    
                {/* Main Content Container */}
                <div className='relative w-full h-screen flex'>

                    {/* Content Container */}
                    <ul className='flex flex-col justify-center items-center w-full h-auto text-white mx-auto relative -top-32'>
                        
                        {/* Reduce Legal Liabilities... */}
                        <li className='text-center text-3xl font-bold font-sans leading-12'>
                            <span className='text-header-teal'>Reduce</span> Legal Liabilities.<br/>
                            <span className='text-header-teal'>Prevent</span> Fraud.<br/>
                            <span className='text-header-teal'>Recover</span> Lost Revenue.
                        </li>

                        {/* Revolutionizing security... */}
                        <li className='text-center text-base text-white font-sans w-2/3 mt-4'>
                            Revolutionizing Security with Advanced Video Analysis Software.
                        </li>

                        {/* Contact Us Button */}
                        <li className='mt-5'>
                            <div className='button-card-wrapper w-32 h-10'>
                                <div className='button-card-content w-32 h-10 flex justify-center'>
                                    <button className='rounded-full w-full h-full px-4' onClick={onContactUsClick}>
                                    Contact Us
                                    </button>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
                
                


            </div>


            {/* 
            ################################################################################################
                                                sm: Tablets (768px) 
            ################################################################################################
            */}

            <div className='hidden sm:flex md:hidden lg:hidden'>

                {/* Image Background */} 
                <img className='flex absolute top-1/2 left-0 z-0 w-full object-cover' src={onePng} alt="" />


                {/* Main Content Container */}
                <div className='relative w-full h-screen flex'>

                    {/* Content Container */}
                    <ul className='flex flex-col justify-center items-center w-905 h-294 text-white mx-auto relative -top-32'>
                        
                        {/* Reduce Legal Liabilities... */}
                        <li className='text-center text-5xl font-bold font-sans leading-12'>
                            <span className='text-header-teal'>Reduce</span> Legal Liabilities.<br/>
                            <span className='text-header-teal'>Prevent</span> Fraud.<br/>
                            <span className='text-header-teal'>Recover</span> Lost Revenue.
                        </li>

                        {/* Revolutionizing security... */}
                        <li className='text-center text-white text-lg font-normal font-sans leading-10'>
                            Revolutionizing Security with Advanced Video Analysis Software.
                        </li>

                        {/* Contact Us Button */}
                        <li className='mt-5'>
                            <div className='button-card-wrapper w-32 h-10'>
                                <div className='button-card-content w-32 h-10 flex justify-center'>
                                    <button className='rounded-full w-full h-full px-4' onClick={onContactUsClick}>
                                    Contact Us
                                    </button>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>

            {/* 
            ################################################################################################
                                                md: Laptop (1024px) 
            ################################################################################################
            */}

            <div className='hidden md:flex lg:hidden'>

                {/* Video Background */} 
                {/* <video className='flex absolute top-[25rem] left-0 z-0 w-full object-cover' autoPlay muted loop playsInline>    
                    <source src={waveLink} type='video/webm' />
                    Your browser does not support the video tag.
                </video> */}
                <img className='flex absolute top-1/2 left-0 z-0 w-full object-cover' src={onePng} alt="" />


                {/* Main Content Container */}
                <div className='relative w-full h-screen flex'>

                    {/* Content Container */}
                    <ul className='flex flex-col justify-center items-center w-905 h-294 text-white mx-auto relative -top-32'>
                        
                        {/* Reduce Legal Liabilities... */}
                        <li className='text-center text-6xl font-bold font-sans leading-12'>
                            <span className='text-header-teal'>Reduce</span> Legal Liabilities.<br/>
                            <span className='text-header-teal'>Prevent</span> Fraud.<br/>
                            <span className='text-header-teal'>Recover</span> Lost Revenue.
                        </li>

                        {/* Revolutionizing security... */}
                        <li className='text-center text-lg text-white font-normal font-sans leading-10'>
                            Revolutionizing Security with Advanced Video Analysis Software.
                        </li>

                        {/* Contact Us Button */}
                        <li className='mt-5'>
                            <div className='button-card-wrapper w-32 h-10'>
                                <div className='button-card-content w-32 h-10 flex justify-center'>
                                    <button className='rounded-full w-full h-full px-4' onClick={onContactUsClick}>
                                    Contact Us
                                    </button>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>


            {/* 
            ################################################################################################
                                                lg: Desktop (1440px) 
            ################################################################################################
            */}

            <div className='hidden lg:flex'>

                {/* Video Background */} 
                {/* <video className='flex absolute top-[20rem] left-0 z-0 w-full object-cover' autoPlay muted loop playsInline>    
                    <source src={waveLink} type='video/webm' />
                    Your browser does not support the video tag.
                </video> */}
                <img className='flex absolute top-1/2 left-0 z-0 w-full object-cover' src={onePng} alt="" />

                {/* Main Content Container */}
                <div className='relative w-full h-screen flex'>

                    {/* Content Container */}
                    <ul className='flex flex-col justify-center items-center w-905 h-294 text-white mx-auto relative -top-20'>
                        
                        {/* Reduce Legal Liabilities... */}
                        <li className='text-center text-6xl font-bold font-sans leading-12'>
                            <span className='text-header-teal'>Reduce</span> Legal Liabilities.<br/>
                            <span className='text-header-teal'>Prevent</span> Fraud.<br/>
                            <span className='text-header-teal'>Recover</span> Lost Revenue.
                        </li>

                        {/* Revolutionizing security... */}
                        <li className='text-center text-lg text-white font-normal font-sans leading-10'>
                            Revolutionizing Security with Advanced Video Analysis Software.
                        </li>

                        {/* Contact Us Button */}
                        <li className='mt-5'>
                            <div className='button-card-wrapper w-32 h-10'>
                                <div className='button-card-content w-32 h-10 flex justify-center'>
                                    <button className='rounded-full w-full h-full px-4' onClick={onContactUsClick}>
                                    Contact Us
                                    </button>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>

            

        </div>

    );
}

export default Header;