/* Navbar.js */

/* React */
import React, { useState, useEffect } from 'react';

/* Data */
import { Logo, menu, close } from '../../assets';

/* Navbar */
const Navbar = ({ onHomeClick, onMissionClick, onSolutionClick, onTailgatingClick, onFacialRecognitionClick, onDashboardClick, onContactUsClick }) => {
    // State to track if the user has scolled
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect( () => {
        // Function to handle scroll events 
        const handleScroll = () => {
            // Get the current scroll position
            const scrollTop = window.pageYOffset;
            // Update the state based on the scroll position
            setIsScrolled(scrollTop > 0);
        };

        // Add scroll event listener when the component mounts 
        window.addEventListener('scroll', handleScroll);

        // Cleanup: Remove scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const [toggle, setToggle] = useState(false);


    return (
        // Navber container with flex layout, centered horizontally 
        <div className={`flex justify-between items-center h-16 w-full px-[0.5vw] text-white font-sans ${isScrolled ? 'bg-opacity-75 backdrop-blur-lg' : ''}`} style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>

            {/* SecureTeck Logo */} 
            <img className="w-40 h-auto cursor-pointer" src={Logo} alt="Logo" onClick={onHomeClick} />

            {/*Text of the Navbar */}
            <ul className='md:flex hidden px-2 justify-between items-center border border-[#00A9D3] rounded-full'>
                <li className='py-2 px-3 font-normal '><button onClick={onHomeClick} className='hover:text-[#00A3D9]'>Home</button></li>
                <li className='py-2 px-3 font-normal '><button onClick={onMissionClick} className='hover:text-[#00A3D9]'>Our Mission</button></li>
                <li className='py-2 px-3 font-normal '><button onClick={onSolutionClick} className='hover:text-[#00A3D9]'>Our Solution</button></li>
                <li className='py-2 px-3 font-normal'><button onClick={onTailgatingClick} className='hover:text-[#00A3D9]'>Tailgating Detection</button></li>
                <li className='py-2 px-3 font-normal'><button onClick={onFacialRecognitionClick} className='hover:text-[#00A3D9]'>Facial Detection</button></li>
                <li className='py-2 px-3 font-normal'><button onClick={onDashboardClick} className='hover:text-[#00A3D9]'>Dashboard</button></li>
                <li className='py-2 px-3 font-normal'><button onClick={onContactUsClick} className='hover:text-[#00A3D9]'>Contact Us</button></li>
            </ul>
            

            {/* Mobile Navbar */}
            <div className='md:hidden flex justify-end items-center mr-2'>
                <img 
                    src={toggle ? close : menu}
                    alt='menu'
                    className='w-[24px] h-[24px] object-contain z-10'
                    onClick={() => setToggle((prev) => !prev)}
                />

                <div
                    className={`${toggle ? 'flex' : 'hidden'} bg-black w-full h-screen absolute top-0 right-0 items-center justify-center sidebar z-2`}
                >
                    {/*Text of the Navbar */}
                    <ul className='flex flex-col justify-center items-center px-4'>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onHomeClick(); setToggle((prev) => !prev); }}>Home</button></li>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onMissionClick(); setToggle((prev) => !prev); }}>Our Mission</button></li>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onSolutionClick(); setToggle((prev) => !prev); }}>Our Solution</button></li>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onTailgatingClick(); setToggle((prev) => !prev); }}>Tailgating Detection</button></li>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onFacialRecognitionClick(); setToggle((prev) => !prev); }}>Facial Detection</button></li>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onDashboardClick(); setToggle((prev) => !prev); }}>Dashboard</button></li>
                        <li className='p-2 mb-4 font-normal text-[5vw] text-[#00A9D3]'><button onClick={() => {onContactUsClick(); setToggle((prev) => !prev); }}>Contact Us</button></li>
                    </ul>
                </div>

            </div>

        </div> 
    );
}

export default Navbar;