/* Our Solution */

/* React */
import React from 'react';

/* Animation Framework */
import { motion } from 'framer-motion';
import { fadeIn } from '../Animations/variants';

/* Data */
import { SolutionImg } from '../../assets';
import { SolutionPoints } from '../../constants';

/* Our Solution Page */
const Solution = () => {
  return (
    <div>
      
      {/* 
      ################################################################################################
                                          xs: Phones (480px)
      ################################################################################################
      */}

      <div className='flex flex-col sm:hidden justify-center items-center w-full h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={SolutionImg} alt="Our Solution" loading='lazy' />

        {/* Our Solution... */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("down", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='mt-10 pb-4'
        >

          {/* Content Container */}
          <ul className='flex flex-col justify-center items-center mb-4'>

            {/* Our Solution */}
            <li className='text-center text-gray text-5xl font-bold font-sans mb-4'>
              Our <span className='text-teal'>Solution</span>
            </li>

            {/* Advanced Video Analysis... */}
            <li className='text-center text-white font-sans w-3/5'>
              Advanced Video Analysis Incorporating Tailgating and Facial Detection.
            </li>

          </ul>

        </motion.div>

        {/* Solution Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("up", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Solution Points */}
          <ul className='flex flex-col w-full'>

            {/* Map Solution Points Array */}
            {SolutionPoints.map((points) => (
              
              /* Black Square */
              <li key={points.id} className='flex w-60 h-48 items-start justify-center rounded-3xl bg-black border-teal border-2 my-4 pt-4 hover:scale-105 transition-transform duration-300'>

                {/* Content Container */}
                <div className='flex flex-col items-center justify-center'>

                  {/* Icon */}
                  <img src={points.icon} alt={points.title} className='w-6 h-6 mb-4' loading='lazy' />

                  {/* Title */}
                  <h3 className='text-gray font-sans font-bold mb-2'>{points.title}</h3>

                  {/* Content */}
                  <p className='w-52 text-xs text-dgray font-sans text-center'>{points.content}</p>

                </div>

              </li>

            ))}

          </ul>

        </motion.div>

      </div>

      {/* 
      ################################################################################################
                                          sm: Tablets (768px) 
      ################################################################################################
      */}

      <div className='hidden sm:flex flex-col md:hidden lg:hidden justify-center items-center w-full h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={SolutionImg} alt="Our Solution" loading='lazy' />

        {/* Our Solution... */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("down", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='mt-10 pb-4'
        >

          {/* Content Container */}
          <ul className='flex flex-col justify-center items-center'>

            {/* Our Solution */}
            <li className='text-center text-gray text-6xl font-bold font-sans mb-4'>
              Our <span className='text-teal'>Solution</span>
            </li>

            {/* Advanced Video Analysis... */}
            <li className='text-center text-white font-bold font-sans'>
              Advanced Video Analysis Incorporating Tailgating and Facial Detection.
            </li>

          </ul>

        </motion.div>

        {/* Solution Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("up", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Solution Points */}
          <ul className='flex flex-col w-full mt-16'>

            {/* Map Solution Points Array */}
            {SolutionPoints.map((points) => (
              
              /* Black Square */
              <li key={points.id} className='flex w-72 h-48 items-start justify-center rounded-3xl bg-black border-teal border-2 my-4 pt-4 hover:scale-105 transition-transform duration-300'>

                {/* Content Container */}
                <div className='flex flex-col items-center justify-center'>

                  {/* Icon */}
                  <img src={points.icon} alt={points.title} className='w-6 h-6 mb-4' loading='lazy' />

                  {/* Title */}
                  <h3 className='text-gray font-sans font-bold mb-2'>{points.title}</h3>

                  {/* Content */}
                  <p className='w-52 text-xs text-dgray font-sans text-center'>{points.content}</p>

                </div>

              </li>

            ))}

          </ul>

        </motion.div>

      </div>

      {/* 
      ################################################################################################
                                          md: Laptop (1024px) 
      ################################################################################################
      */}

      <div className='hidden md:flex lg:hidden flex-col justify-center items-center w-full h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={SolutionImg} alt="Our Solution" loading='lazy' />

        {/* Our Solution... */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("down", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='pb-24 mt-20'
        >

          {/* Content Container */}
          <ul className='flex flex-col justify-center items-center'>

            {/* Our Solution */}
            <li className='text-center text-gray text-8xl font-bold font-sans mb-4'>
              Our <span className='text-teal'>Solution</span>
            </li>

            {/* Advanced Video Analysis... */}
            <li className='text-center text-white text-xl font-bold font-sans w-3/5'>
              Advanced Video Analysis Incorporating Tailgating and Facial Detection.
            </li>

          </ul>

        </motion.div>

        {/* Solution Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("up", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Solution Points */}
          <ul className='flex w-full'>

            {/* Map Solution Points Array */}
            {SolutionPoints.map((points) => (
              
              /* Black Square */
              <li key={points.id} className='flex w-64 h-64 items-start justify-center rounded-3xl bg-black border-teal border-2 mx-10 pt-4 hover:scale-105 transition-transform duration-300'>

                {/* Content Container */}
                <div className='flex flex-col items-center justify-center'>

                  {/* Icon */}
                  <img src={points.icon} alt={points.title} className='w-8 h-8 mb-4' loading='lazy' />

                  {/* Title */}
                  <h3 className='text-gray text-lg font-sans font-bold mb-4'>{points.title}</h3>

                  {/* Content */}
                  <p className='w-44 text-xs text-dgray font-sans text-center'>{points.content}</p>

                </div>

              </li>

            ))}

          </ul>

        </motion.div>

      </div>

      {/* 
      ################################################################################################
                                          lg: Desktop (1440px) 
      ################################################################################################
      */}

      <div className='hidden lg:flex flex-col justify-center items-center w-full h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={SolutionImg} alt="Our Solution" loading='lazy' />
        
        {/* Our Solution... */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("down", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        
          className='pb-24 mt-20'
        >

          {/* Content Container */}
          <ul className='flex flex-col justify-center items-center'>

            {/* Our Solution */}
            <li className='text-center text-gray text-6xl font-bold font-sans mb-4'>
              Our <span className='text-teal'>Solution</span>
            </li>

            {/* Advanced Video Analysis... */}
            <li className='text-center text-white text-base font-bold font-sans w-3/5'>
              Advanced Video Analysis Incorporating Tailgating and Facial Detection.
            </li>

          </ul>

        </motion.div>

        {/* Solution Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("up", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Solution Points */}
          <ul className='flex w-full'>

            {/* Map Solution Points Array */}
            {SolutionPoints.map((points) => (
              
              /* Black Square */
              <li key={points.id} className='flex w-[16rem] h-52 items-start justify-center rounded-3xl bg-black border-teal border-2 mx-16 pt-4 hover:scale-105 transition-transform duration-300'>

                {/* Content Container */}
                <div className='flex flex-col items-center justify-center'>

                  {/* Icon */}
                  <img src={points.icon} alt={points.title} className='w-6 h-6 mb-4' loading='lazy' />

                  {/* Title */}
                  <h3 className='text-gray text-base font-sans font-bold mb-4'>{points.title}</h3>

                  {/* Content */}
                  <p className='w-56 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

                </div>

              </li>

            ))}

          </ul>

        </motion.div>

      </div>

    </div>
  );
}

export default Solution;