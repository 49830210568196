/* App.js */

/* React */
import React, { useRef } from 'react';

/* Import the Components of the Page */
import Navbar from './components/Navbar/Navbar';                   
import Header from './components/Header/Header';
import AboutUs from './components/AboutUs/AboutUs'; 
import Solution from './components/Solution/Solution';                      
import Tailgating from './components/Tailgating/Tailgating';
import FacialRecognition from './components/Facial Recognition/FacialRecognition';
import Dashboard from './components/Dashboard/Dashboard';
import ContactUs from './components/ContactUs/ContactUs';              

const App = () => {

  /* Scrolling Tags */
  const missionRef = useRef(null);
  const solutionRef = useRef(null);
  const tailgatingRef = useRef(null);
  const facialRecognitionRef = useRef(null);
  const dashboardRef = useRef(null);
  const contactUsRef = useRef(null);



  /* 

  
  
  #######################################
            SCROLLING ACTIONS 
  #######################################



  */

  /* Header Scrolling */
  const scrollToHeader = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  /* About Us Scrolling */
  const scrollToAboutUs = () => {
    missionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  /* Our Solution Scrolling */
  const scrollToSolution = () => {
    solutionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  /* Tailgating Scrolling */
  const scrollToTailgating = () => {
    tailgatingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  /* Facial Recognition Scrolling */
  const scrollToFacialRecognition = () => {
    facialRecognitionRef.current.scrollIntoView({behavior: 'smooth' });
  };

  /* Dashboard Scrolling */
  const scrollToDashboard = () => {
    dashboardRef.current.scrollIntoView({behavior: 'smooth'});
  };

  /* Contact Us Scrolling */
  const scrollToContactUs = () => {
    contactUsRef.current.scrollIntoView({behavior: 'smooth'});
  };

  /* 


  ####################################################
                LOADING THE WEBSITE
  ####################################################



  */

  return (
    <div>
      
      {/* Render the Navbar */}
      <div>
        <Navbar 
          /* Connect the Clicking to Scrolling to Tags */
          onHomeClick={scrollToHeader}
          onMissionClick={scrollToAboutUs}
          onSolutionClick={scrollToSolution}
          onTailgatingClick={scrollToTailgating}
          onFacialRecognitionClick={scrollToFacialRecognition}
          onDashboardClick={scrollToDashboard} 
          onContactUsClick={scrollToContactUs}
        />
      </div>
      
      {/* Render the Header Page */}
      <div>
        <Header 
          onContactUsClick={scrollToContactUs}
        /> 
      </div>

      {/* Space Between the Pages Mobile */}
      <div className='bg-black h-32 sm:hidden flex'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-10 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Laptop */}
      <div className='bg-black h-20 hidden md:flex lg:hidden'></div>

      {/* Space Between the Pages Desktop */}
      <div className='bg-black h-40 lg:flex hidden'></div>


      {/* Render the About Us Page */}
      <div ref={missionRef}>                                  {/* About Us Tag for Scrolling */}
        <AboutUs />
      </div>

      {/* Space Between the Pages Mobile */}
      <div className='bg-black h-32 sm:hidden flex'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-32 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Laptop */}
      <div className='bg-black h-20 hidden md:flex lg:hidden'></div>

      {/* Space Between the Pages Desktop */}
      <div className='bg-black h-10 lg:flex hidden'></div>

      {/* Render the Solution Page */}
      <div ref={solutionRef}>                                 {/* Our Solution Tag for Scrolling */}
        <Solution />
      </div>

      {/* Space Between the Pages Mobile */}
      <div className='bg-black h-32 sm:hidden flex'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-20 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Desktop */}
      <div className='bg-black h-20 lg:flex hidden'></div>
      
      {/* Render the Tailgating Page */}
      <div ref={tailgatingRef}>                               {/* Tailgating Tag for Scrolling */}
        <Tailgating />
      </div>
      
      {/* Space Between the Pages Mobile */}
      <div className='bg-black h-96 sm:hidden flex'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-0 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-20 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Desktop */}
      <div className='bg-black h-0 lg:flex hidden'></div>


      {/* Render the Facial Recognition Page */}
      <div ref={facialRecognitionRef}>                        {/* Facial Recognition Tag for Scrolling */}
        <FacialRecognition />
      </div>

      {/* Space Between the Pages Mobile */}
      <div className='bg-black h-[28rem] sm:hidden flex'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-56 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-20 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Desktop */}
      <div className='bg-black h-20 lg:flex hidden'></div>

      {/* Render the Dashboard Page */}
      <div ref={dashboardRef}>                                {/* Dashboard Tag for Scrolling */}
        <Dashboard />
      </div>


      {/* Space Between the Pages Mobile */}
      <div className='bg-black h-8 sm:hidden flex'></div>

      {/* Space Between the Pages Tablet */}
      <div className='bg-black h-40 hidden sm:flex md:hidden lg:hidden'></div>

      {/* Space Between the Pages Desktop */}
      <div className='bg-black h-10 lg:flex hidden'></div>

      {/* Render the Contact Us Page */}
      <div ref={contactUsRef}>                                {/* Contact Us Tag for Scrolling */}
        <ContactUs />
      </div>

    </div>
  );
}

export default App;