/* About Us */

/* React */
import React from 'react';

/* Animation Framework */
import { motion } from 'framer-motion';
import { fadeIn } from '../Animations/variants';

/* Data */
import { Mission } from '../../assets';
import { AboutUsPoints } from '../../constants';

/* About Us Page */
const AboutUs = () => {
  return (
    
    <div>

      {/* 
      ################################################################################################
                                          xs: Phones (480px)
      ################################################################################################
      */}
      
      <div className='flex flex-col sm:hidden justify-center items-center w-screen h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={Mission} alt="Our Mission" loading='lazy' />

        {/* Our Mission */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >
          {/* Our Mission Content Container */}
          <ul className='flex flex-col justify-start items-center mt-24'>

            {/* Our */}
            <li className='text-left text-gray text-5xl font-bold'>
              Our
            </li>

            {/* Mission */}
            <li className='text-left text-teal text-5xl font-bold'>
              Mission
            </li>

          </ul>
        </motion.div>

        {/* Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Mission Points */}
          <ul className='flex flex-col items-center justify-center mt-24'>

            {/* Map About Us Points Array */}
            {AboutUsPoints.map((points) => (
              <li key={points.id} className='flex flex-col items-center w-4/5 mb-16'>
                <p className='text-base text-gray font-sans w-full text-left'>{points.content}</p>
              </li>
            ))}

          </ul>

        </motion.div>

      </div>

      {/* 
      ################################################################################################
                                          sm: Tablets (768px) 
      ################################################################################################
      */}

      <div className='hidden sm:flex flex-col md:hidden lg:hidden justify-center items-center w-screen h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={Mission} alt="Our Mission" loading='lazy' />

        {/* Our Mission */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >
          {/* Our Mission Content Container */}
          <ul className='flex flex-col justify-start items-center mt-24'>

            {/* Our */}
            <li className='text-left text-gray text-6xl font-bold'>
              Our
            </li>

            {/* Mission */}
            <li className='text-left text-teal text-6xl font-bold'>
              Mission
            </li>

          </ul>
        </motion.div>

        {/* Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Mission Points */}
          <ul className='flex flex-col items-center justify-center mt-24'>

            {/* Map About Us Points Array */}
            {AboutUsPoints.map((points) => (
              <li key={points.id} className='flex flex-col items-center w-4/5 mb-16'>
                <p className='text-base text-gray font-sans w-full text-left'>{points.content}</p>
              </li>
            ))}

          </ul>

        </motion.div>

      </div>

      {/* 
      ################################################################################################
                                          md: Laptop (1024px) 
      ################################################################################################
      */}

      <div className='hidden md:flex lg:hidden justify-around items-center w-full h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={Mission} alt="Our Mission" loading='lazy' />

        {/* Our Mission */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >
          {/* Our Mission Content Container */}
          <ul className='flex flex-col justify-center items-left w-auto pl-10 mr-40'>

            {/* Our */}
            <li className='text-left text-gray text-7xl font-bold'>
              Our
            </li>

            {/* Mission */}
            <li className='text-left text-teal text-7xl font-bold'>
              Mission
            </li>

          </ul>
        </motion.div>

        {/* Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Mission Points */}
          <ul className='flex flex-col items-left justify-center w-5/6 mt-24'>

            {/* Map About Us Points Array */}
            {AboutUsPoints.map((points) => (
              <li key={points.id} className='flex flex-col items-center mb-16'>
                <p className='text-base text-gray font-sans w-full text-left'>{points.content}</p>
              </li>
            ))}

          </ul>

        </motion.div>

      </div>


      {/* 
      ################################################################################################
                                          lg: Desktop (1440px) 
      ################################################################################################
      */}

      <div className='hidden lg:flex justify-around items-center w-full h-screen'>

        {/* Background Image */}
        <img className='absolute w-full h-full -z-10' src={Mission} alt="Our Mission" loading='lazy' />

        {/* Our Mission */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >
          {/* Our Mission Content Container */}
          <ul className='flex flex-col justify-center items-left w-auto'>

            {/* Our */}
            <li className='text-left text-gray text-7xl font-bold font-sans'>
              Our
            </li>

            {/* Mission */}
            <li className='text-left text-teal text-7xl font-bold font-sans'>
              Mission
            </li>

          </ul>
        </motion.div>

        {/* Points */}
        <motion.div
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
        >

          {/* Mission Points */}
          <ul className='flex flex-col items-left justify-center w-[50vw] mt-24'>

            {/* Map About Us Points Array */}
            {AboutUsPoints.map((points) => (
              <li key={points.id} className='flex flex-col items-center mb-16'>
                <p className='text-base text-gray font-sans w-3/4 text-left'>{points.content}</p>
              </li>
            ))}

          </ul>

        </motion.div>


      </div>


    </div>
  );
}

export default AboutUs;