/* Modal.js */

/* React */
import React from 'react';

/* Modal Code */
const Modal = ({ open, onClose }) => {
  return (
    <div onClick={onClose} className={`absolute inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : "invisible"}`}>
        <div 
            onClick={(e) => e.stopPropagation()}
            className={`flex flex-col w-[20rem] h-[12rem] justify-center items-center bg-white rounded-xl shadow p-6 transition-all ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}
        >
            <span className='text-lg'>Message sent successfully!</span> 
            <span className='text-lg'>We will get back to you shortly.</span>
            <button onClick={onClose} className='mt-10 px-4 py-2 rounded-full  bg-teal hover:bg-[#003744] text-white hover:text-white'>
                Close
            </button>
        </div>
    </div>
  );
};

export default Modal;