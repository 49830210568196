/* Dashboard.js */

/* React */
import React from 'react';

/* Animation Framework */
import { motion } from 'framer-motion';
import { fadeIn } from '../Animations/variants';

/* Data */
import { DashboardLeftPoints, DashboardRightPoints } from '../../constants';
import { dashboardimg } from '../../assets';

/* Dashboard Page */
const Dashboard = () => {
  return (
    <div>

      {/* 
      ################################################################################################
                                          xs: Phones (480px)
      ################################################################################################
      */}

      <div className='sm:hidden flex flex-col h-screen items-center justify-center'>

        {/* Welcome To Your Dashboard */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("down", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center mt-20'
        >

          {/* Black Board */}
          <div className='w-[16rem] h-[8rem] bg-black rounded-3xl border-2 border-teal flex items-center justify-center relative z-10'>

            {/* Text */}
            <div className='w-[16rem] text-center text-gray text-2xl font-sans font-bold'>Welcome To Your Dashboard</div>

          </div>

        </motion.div>

        {/* Laptop Image */}
        <img className='w-[25rem] h-[15rem] relative -z-10 mb-10 mt-16' src={dashboardimg} alt="" />

        {/* Bottom Portion */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.5)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-between'
        >
          
          {/* LS Points List */}
          <ul className='flex flex-col w-full items-center justify-center mr-2'>

            {/* Map LS Points Array */}
            {DashboardLeftPoints.map((points) => (
                
                <li key={points.id} className='flex flex-col items-center justify-center relative w-40 h-36 mb-10'>

                  {/* Box */}

                  <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                  {/* Top Line */}
                  <div className='flex items-center mb-4 w-36 justify-between'>

                    {/* Icon Container */}
                    <div className='flex-shrink-0 ml-2'>
                      <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-36 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

                </li>
            ))}

          </ul>

          {/* RS Points List */}
          <ul className='flex flex-col w-full items-center justify-center ml-2'>
            
            {/* Map RS Points Array */}
            {DashboardRightPoints.map((points) => (

              <li key={points.id} className='flex flex-col items-center justify-center relative w-40 h-36 mb-10'>

                {/* Box */}

                <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                {/* Top Line */}
                <div className='flex items-center mb-4 w-36 justify-between'>

                  {/* Icon Container */}
                  <div className='flex-shrink-0 ml-2'>
                    <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                  </div>

                  {/* Title Container */}
                  <div className='flex-grow text-center'>
                    <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                  </div>

                </div>

                {/* Bottom Line Content */}
                <p className='w-36 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

              </li>

            ))}

          </ul>

        </motion.div>

      </div>
      

      {/* 
      ################################################################################################
                                          sm: Tablets (768px)
      ################################################################################################
      */}

      <div className='hidden sm:flex md:hidden lg:hidden flex-col h-screen items-center justify-center mt-28'>

        {/* Welcome To Your Dashboard */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("down", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center mt-72'
        >

          {/* Black Board */}
          <div className='w-[16rem] h-[8rem] bg-black rounded-3xl border-2 border-teal flex items-center justify-center relative z-10'>

            {/* Text */}
            <div className='w-[16rem] text-center text-gray text-2xl font-sans font-bold'>Welcome To Your Dashboard</div>

          </div>

        </motion.div>

        {/* Laptop Image */}
        <img className='w-[40rem] h-[34rem] mb-10 mt-4 ml-5 relative -z-10' src={dashboardimg} alt="" />


        {/* Bottom Portion */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.5)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center'
        >
          
          {/* LS Points List */}
          <ul className='flex flex-col w-full items-center justify-center mr-20'>

            {/* Map LS Points Array */}
            {DashboardLeftPoints.map((points) => (
                
                <li key={points.id} className='flex flex-col items-center justify-center relative w-48 h-36 mb-10'>

                  {/* Box */}

                  <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                  {/* Top Line */}
                  <div className='flex items-center mb-4 w-40 justify-between'>

                    {/* Icon Container */}
                    <div className='flex-shrink-0 ml-2'>
                      <img src={points.icon} alt={points.title} className='w-8 h-8' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-40 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

                </li>
            ))}

          </ul>

          {/* RS Points List */}
          <ul className='flex flex-col w-full items-center justify-center ml-4'>
            
            {/* Map RS Points Array */}
            {DashboardRightPoints.map((points) => (

              <li key={points.id} className='flex flex-col items-center justify-center relative w-48 h-36 mb-10'>

                {/* Box */}

                <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                {/* Top Line */}
                <div className='flex items-center mb-4 w-40 justify-between'>

                  {/* Icon Container */}
                  <div className='flex-shrink-0 ml-2'>
                    <img src={points.icon} alt={points.title} className='w-8 h-8' loading='lazy' />
                  </div>

                  {/* Title Container */}
                  <div className='flex-grow text-center'>
                    <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                  </div>

                </div>

                {/* Bottom Line Content */}
                <p className='w-40 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

              </li>

            ))}

          </ul>

        </motion.div>

      </div>
      
      {/* 
      ################################################################################################
                                          md: Laptop (1024px) 
      ################################################################################################
      */}

      <div className='hidden md:flex lg:hidden justify-between w-full h-screen items-center px-6'>

        {/* Left Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.5)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center'
        >
          
          {/* LS Points List */}
          <ul className='flex flex-col w-full'>

            {/* Map LS Points Array */}
            {DashboardLeftPoints.map((points) => (
                
                <li key={points.id} className='flex flex-col items-center justify-center relative w-44 h-36 mb-20'>

                  {/* Box */}

                  <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                  {/* Top Line */}
                  <div className='flex items-center mb-4 w-32 justify-between'>

                    {/* Icon Container */}
                    <div className='flex-shrink-0'>
                      <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-36 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

                </li>
            ))}

          </ul>

        </motion.div>





        {/* Middle Portion */}
        <div className='flex flex-col h-screen items-center'>

          {/* Welcome To Your Dashboard */}
          <motion.div 
            // Motion Elements
            // fadeIn(direction, delay)
            // viewport: once: T/F, if animation happens once or always. 
            // viewport: amount: portion of the item on screen for animation to occur
            variants={fadeIn("down", 0.2)}
            initial= "hidden"
            whileInView={"show"}
            viewport={{once: true, amount: 0.3}}
          
            className='relative flex items-center justify-center mt-20 ml-4'
          >

            {/* Black Board */}
            <div className='w-[18rem] h-[8rem] bg-black rounded-3xl border-2 border-teal flex items-center justify-center relative z-10'>

              {/* Text */}
              <div className='w-[24rem] text-center text-dgray text-3xl font-sans font-bold'>Welcome To Your Dashboard</div>
          
            </div>

          </motion.div>

          {/* Laptop Image */}
          <img className='w-[40rem] h-[26rem] absolute -z-10 mt-64 ml-4' src={dashboardimg} alt="" />


        </div>





        {/* Right Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("left", 0.5)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center'
        >

          {/* RS Points List */}
          <ul className='flex flex-col w-full'>
            
            {/* Map RS Points Array */}
            {DashboardRightPoints.map((points) => (

              <li key={points.id} className='flex flex-col items-center justify-center relative w-44 h-36 mb-20'>

                {/* Box */}

                <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                {/* Top Line */}
                <div className='flex items-center mb-4 w-32 justify-between'>

                  {/* Icon Container */}
                  <div className='flex-shrink-0'>
                    <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                  </div>

                  {/* Title Container */}
                  <div className='flex-grow text-center'>
                    <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                  </div>

                </div>

                {/* Bottom Line Content */}
                <p className='w-36 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

              </li>

            ))}

          </ul>
          
        </motion.div>

      </div>

      
      {/* 
      ################################################################################################
                                          lg: Desktop (1440px) 
      ################################################################################################
      */}

      <div className='hidden lg:flex justify-around w-full h-screen items-center'>

        {/* Left Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.5)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center'
        >
          
          {/* LS Points List */}
          <ul className='flex flex-col w-full'>

            {/* Map LS Points Array */}
            {DashboardLeftPoints.map((points) => (
                
                <li key={points.id} className='flex flex-col items-center justify-center relative w-56 h-40 mb-20'>

                  {/* Box */}

                  <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                  <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                  <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                  {/* Top Line */}
                  <div className='flex items-center mb-4 w-44 justify-between'>

                    {/* Icon Container */}
                    <div className='flex-shrink-0'>
                      <img src={points.icon} alt={points.title} className='w-8 h-8' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-44 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

                </li>
            ))}

          </ul>

        </motion.div>





        {/* Middle Portion */}
        <div className='flex flex-col h-screen items-center'>

          {/* Welcome To Your Dashboard */}
          <motion.div 
            // Motion Elements
            // fadeIn(direction, delay)
            // viewport: once: T/F, if animation happens once or always. 
            // viewport: amount: portion of the item on screen for animation to occur
            variants={fadeIn("down", 0.2)}
            initial= "hidden"
            whileInView={"show"}
            viewport={{once: true, amount: 0.3}}
          
            className='relative flex items-center justify-center mt-20'
          >

            {/* Black Board */}
            <div className='w-[20rem] h-[8rem] bg-black rounded-3xl border-2 border-teal flex items-center justify-center relative z-10'>

              {/* Text */}
              <div className='w-[26rem] text-center text-gray text-3xl font-sans font-bold'>Welcome To Your Dashboard</div>
          
            </div>

          </motion.div>

          {/* Laptop Image */}
          <img className='w-[45rem] h-[30rem] absolute -z-10 mt-52 ml-4' src={dashboardimg} alt="" />


        </div>





        {/* Right Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("left", 0.5)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}

          className='relative flex items-center justify-center'
        >

          {/* RS Points List */}
          <ul className='flex flex-col w-full'>
            
            {/* Map RS Points Array */}
            {DashboardRightPoints.map((points) => (

              <li key={points.id} className='flex flex-col items-center justify-center relative w-56 h-40 mb-20'>

                {/* Box */}

                <div className="w-5 h-5 left-0 top-0 absolute border-l-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 top-0 absolute border-r-2 border-t-2 border-cyan-500"></div>
                <div className="w-5 h-5 left-0 bottom-0 absolute border-l-2 border-b-2 border-cyan-500"></div>
                <div className="w-5 h-5 right-0 bottom-0 absolute border-r-2 border-b-2 border-cyan-500"></div>

                {/* Top Line */}
                <div className='flex items-center mb-4 w-44 justify-between'>

                  {/* Icon Container */}
                  <div className='flex-shrink-0'>
                    <img src={points.icon} alt={points.title} className='w-8 h-8' loading='lazy' />
                  </div>

                  {/* Title Container */}
                  <div className='flex-grow text-center'>
                    <h3 className='text-base text-gray font-sans font-bold'>{points.title}</h3>
                  </div>

                </div>

                {/* Bottom Line Content */}
                <p className='w-44 text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>

              </li>

            ))}

          </ul>
          
        </motion.div>

      </div>
      
    </div>
  )
}

export default Dashboard;