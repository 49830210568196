/* ContactUs.js */

/* React */
import React, { useState } from 'react';

/* Animation Framework */
import { motion } from 'framer-motion';
import { fadeIn } from '../Animations/variants';

/* Data */
import Modal from './Modal';


/* Contact Us Page */
const ContactUs = () => {

    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

     // Function to handle form submission
     const handleSubmit = async (event) => {
        event.preventDefault();  // Prevent default form submission behavior

        setName('');
        setEmail('');
        setMessage('');

        try {
            const response = await fetch('https://stbackend.netlify.app/.netlify/functions/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, message }),
            });
            setOpen(true);
            const responseData = await response.json();
            console.log(responseData.message);  // Display the server response message
        } catch (error) {
            console.error('Failed to send email:', error);
        }
    };

    return (
    <div>
        
        {/* 
        ################################################################################################
                                            xs: Phones (480px)
        ################################################################################################
        */}
        <div className='sm:hidden flex flex-col h-screen items-center justify-between relative'>

            {/* Top Portion */}
            <motion.div
                // Motion Elements
                // fadeIn(direction, delay)
                // viewport: once: T/F, if animation happens once or always. 
                // viewport: amount: portion of the item on screen for animation to occur
                variants={fadeIn("down", 0.2)}
                initial= "hidden"
                whileInView={"show"}
                viewport={{once: true, amount: 0.3}}

                className='relative flex flex-col justify-center items-center py-6 mt-20'
            >

                {/* Contact Us */}
                <div className='text-center text-gray text-5xl font-bold font-sans'>
                    Contact Us
                </div>

                {/* Questions */}
                <div className='text-center text-dgray text-lg font-sans'>
                    Any questions or remarks? Just write us a message!
                </div>

            </motion.div>

            {/* Bottom Portion */}
            <motion.div
                // Motion Elements
                // fadeIn(direction, delay)
                // viewport: once: T/F, if animation happens once or always. 
                // viewport: amount: portion of the item on screen for animation to occur
                variants={fadeIn("up", 0.2)}
                initial= "hidden"
                whileInView={"show"}
                viewport={{once: true, amount: 0.3}}

                className='relative flex items-center justify-center mb-20'
            >

                {/* Black Board */}
                <div className=' w-[85vw] h-[70vh] bg-black rounded-3xl border-2 border-teal flex flex-col items-center justify-center relative z-10'>
                    
                    <form onSubmit={handleSubmit} autoComplete='off' className='flex flex-col items-center justify-center'>

                        {/* Name, Email, Message */}
                        <ul className='flex flex-col w-[70vw]'>

                            {/* Your Name */}
                            <li className='h-[6vh] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                <input 
                                    type='text'
                                    name='name'
                                    placeholder='Your Name'
                                    autoComplete='off'
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-[#00A9D3]'
                                />
                            </li>

                            {/* Your Email */}
                            <li className='h-[6vh] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                <input 
                                    type='text'
                                    name='email'
                                    placeholder='Your Email'
                                    autoComplete='off'
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-[#00A9D3]'
                                />
                            </li>

                            {/* Message */}
                            <li className='h-[30vh] p-2.5 bg-black rounded-xl border-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-start mb-6'>
                                <textarea 
                                    name='message' 
                                    placeholder='Message'
                                    autoComplete='off'
                                    required
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                    className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none resize-none placeholder:text-[#00A9D3]'
                                />
                            </li>  

                        </ul>

                        {/* Send Message Button */}
                        <div className='w-[45vw] h-[6vh] mt-6 p-2.5 bg-teal opacity-70 rounded-full flex justify-center items-center'>
                            <button className='h-6 text-center text-white text-l font-semibold font-sans'>
                                Send Message
                            </button>
                        </div>

                    </form>

                    <Modal open={open} onClose={() => setOpen(false)} />

                </div>

            </motion.div>            

        </div>
            
        {/* 
        ################################################################################################
                                            sm: Tablets (768px)
        ################################################################################################
        */}

        <div className='hidden sm:flex md:hidden lg:hidden flex-col h-screen items-center justify-center relative'>

            {/* Top Portion */}
            <motion.div
                // Motion Elements
                // fadeIn(direction, delay)
                // viewport: once: T/F, if animation happens once or always. 
                // viewport: amount: portion of the item on screen for animation to occur
                variants={fadeIn("down", 0.2)}
                initial= "hidden"
                whileInView={"show"}
                viewport={{once: true, amount: 0.3}}

                className='relative flex flex-col justify-center items-center mt-20 mb-10'
            >

                {/* Contact Us */}
                <div className='text-center text-gray text-6xl font-bold font-sans'>
                    Contact Us
                </div>

                {/* Contact Us Sentence */}
                <div className='text-center text-dgray text-xl font-normal font-sans'>
                    Any questions or remarks? Just write us a message!
                </div>

            </motion.div>

            {/* Bottom Portion */}
            <motion.div
                // Motion Elements
                // fadeIn(direction, delay)
                // viewport: once: T/F, if animation happens once or always. 
                // viewport: amount: portion of the item on screen for animation to occur
                variants={fadeIn("up", 0.2)}
                initial= "hidden"
                whileInView={"show"}
                viewport={{once: true, amount: 0.3}}

                className='relative flex items-center justify-center mb-12'
            >

                {/* Black Board */}
                <div className='w-[85vw] h-[70vh] bg-black rounded-3xl border-2 border-teal flex flex-col items-center justify-center relative z-10'>
                    
                    <form onSubmit={handleSubmit} autoComplete='off' className='flex flex-col items-center justify-center'>

                        {/* Name, Email, Message */}
                        <ul className='flex flex-col w-[70vw]'>

                            {/* Your Name */}
                            <li className='h-[6vh] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                <input 
                                    type='text'
                                    name='name'
                                    placeholder='Your Name'
                                    autoComplete='off'
                                    required
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-teal'
                                />
                            </li>

                            {/* Your Email */}
                            <li className='h-[6vh] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                <input 
                                    type='text'
                                    name='email'
                                    placeholder='Your Email'
                                    autoComplete='off'
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-teal'
                                />
                            </li>

                            {/* Message */}
                            <li className='h-[30vh] p-2.5 bg-black rounded-xl border-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-start mb-6'>
                                <textarea 
                                    name='message' 
                                    placeholder='Message'
                                    autoComplete='off'
                                    required
                                    value={message}
                                    onChange={e => setMessage(e.target.value)}
                                    className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none resize-none placeholder:text-teal'
                                />
                            </li>  

                        </ul>

                        {/* Send Message Button */}
                        <div className='w-[30vw] h-[6vh] mt-6 p-2.5 bg-teal opacity-70 rounded-full flex justify-center items-center'>
                            <button type='submit' className='h-6 text-center text-white text-lg font-semibold font-sans'>
                                Send Message
                            </button>
                        </div>

                    </form>

                    <Modal open={open} onClose={() => setOpen(false)} />

                </div>

            </motion.div>            

        </div>

        {/* 
        ################################################################################################
                                            md: Laptop (1024px) 
        ################################################################################################
        */}
        <div className='hidden md:flex lg:hidden flex-col w-full h-screen items-center justify-center'>
                {/* Top Portion */}
                <motion.div
                    // Motion Elements
                    // fadeIn(direction, delay)
                    // viewport: once: T/F, if animation happens once or always. 
                    // viewport: amount: portion of the item on screen for animation to occur
                    variants={fadeIn("down", 0.2)}
                    initial= "hidden"
                    whileInView={"show"}
                    viewport={{once: true, amount: 0.3}}

                    className='relative flex flex-col justify-center items-center py-6 mb-4'
                >

                    {/* Contact Us */}
                    <div className='text-center text-gray text-6xl font-bold font-sans'>
                        Contact Us
                    </div>

                    {/* Contact Us Sentence */}
                    <div className='text-center text-dgray text-xl font-normal font-sans'>
                        Any questions or remarks? Just write us a message!
                    </div>


                </motion.div>

                {/* Bottom Portion */}
                <motion.div
                    // Motion Elements
                    // fadeIn(direction, delay)
                    // viewport: once: T/F, if animation happens once or always. 
                    // viewport: amount: portion of the item on screen for animation to occur
                    variants={fadeIn("up", 0.2)}
                    initial= "hidden"
                    whileInView={"show"}
                    viewport={{once: true, amount: 0.3}}

                    className='relative flex items-center justify-center'
                >
                    
                    {/* Black Board */}
                    <div className='w-[60vw] h-[70vh] bg-black rounded-3xl border-4 border-teal flex flex-col items-center justify-center relative z-10'>
                        
                        <form onSubmit={handleSubmit} autoComplete='off' className='flex flex-col items-center justify-center'>

                            {/* Name, Email, Message */}
                            <ul className='flex flex-col w-[50vw]'>

                                {/* Your Name */}
                                <li className='h-[6vh] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                    <input 
                                        type='text'
                                        name='name'
                                        placeholder='Your Name'
                                        autoComplete='off'
                                        required
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-teal'
                                    />
                                </li>

                                {/* Your Email */}
                                <li className='h-[6vh] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                    <input 
                                        type='text'
                                        name='email'
                                        placeholder='Your Email'
                                        autoComplete='off'
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-teal'
                                    />
                                </li>

                                {/* Message */}
                                <li className='h-[30vh] p-2.5 bg-black rounded-xl border-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-start mb-6'>
                                    <textarea
                                        name='message' 
                                        placeholder='Message'
                                        autoComplete='off'
                                        required
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none resize-none placeholder:text-teal'
                                    />
                                </li>  

                            </ul>

                            {/* Send Message Button */}
                            <div className='w-[15vw] h-14 mt-6 p-2.5 bg-teal opacity-70 rounded-full flex justify-center items-center'>
                                <button type='submit' className='h-6 text-center text-white text-l font-semibold font-sans'>
                                    Send Message
                                </button>
                            </div>

                        </form>

                        <Modal open={open} onClose={() => setOpen(false)} />
                


                    </div>

                </motion.div>

        </div>

        {/* 
        ################################################################################################
                                            lg: Desktop (1440px) 
        ################################################################################################
        */}
        <div className='hidden lg:flex flex-col w-full h-screen items-center justify-center'>
            
                {/* Top Portion */}
                <motion.div
                    // Motion Elements
                    // fadeIn(direction, delay)
                    // viewport: once: T/F, if animation happens once or always. 
                    // viewport: amount: portion of the item on screen for animation to occur
                    variants={fadeIn("down", 0.2)}
                    initial= "hidden"
                    whileInView={"show"}
                    viewport={{once: true, amount: 0.3}}

                    className='relative flex flex-col justify-center items-center py-6 mt-10'
                >

                    {/* Contact Us */}
                    <div className='text-center text-dgray text-5xl font-bold font-sans mb-2'>
                        Contact Us
                    </div>

                    {/* Contact Us Sentence */}
                    <div className='text-center text-dgray text-xl font-normal font-sans'>
                        Any questions or remarks? Just write us a message!
                    </div>


                </motion.div>

                {/* Bottom Portion */}
                <motion.div
                    // Motion Elements
                    // fadeIn(direction, delay)
                    // viewport: once: T/F, if animation happens once or always. 
                    // viewport: amount: portion of the item on screen for animation to occur
                    variants={fadeIn("up", 0.2)}
                    initial= "hidden"
                    whileInView={"show"}
                    viewport={{once: true, amount: 0.3}}

                    className='relative flex items-center justify-center'
                >

                    {/* Black Board */}
                    <div className='w-[40rem] h-[35rem] bg-black rounded-3xl border-2 border-teal flex flex-col items-center justify-center relative z-10'>
                        
                        <form onSubmit={handleSubmit} autoComplete='off' className='flex flex-col items-center justify-center'>

                            {/* Name, Email, Message */}
                            <ul className='flex flex-col w-[30rem]'>

                                {/* Your Name */}
                                <li className='h-[3rem] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                    <input 
                                        type='text'
                                        name='name'
                                        placeholder='Your Name'
                                        autoComplete='off'
                                        required
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-teal'
                                    />
                                </li>

                                {/* Your Email */}
                                <li className='h-[3rem] p-2.5 bg-black rounded-xl border-b-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-end mb-6'>
                                    <input 
                                        type='email'
                                        name='email'
                                        placeholder='Your Email'
                                        autoComplete='off'
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none placeholder:text-teal'
                                    />
                                </li>

                                {/* Message */}
                                <li className='h-[16rem] p-2.5 bg-black rounded-xl border-2 border-zinc-600 opacity-70 items-start gap-2.5 flex flex-col justify-start mb-6'>
                                    <textarea
                                        name='message' 
                                        placeholder='Message'
                                        autoComplete='off'
                                        required
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        className='w-full h-full bg-transparent text-left text-teal font-sans leading-snug border-none outline-none resize-none placeholder:text-teal'
                                    />
                                </li>  

                            </ul>

                            {/* Send Message Button */}
                            <div className='w-40 h-10 bg-teal rounded-full flex justify-center items-center hover:bg-[#003744]'>
                                <button type='submit' className='w-full h-full text-center text-dgray hover:text-white text-base font-semibold font-sans'>
                                    Send Message
                                </button>
                            </div>

                        </form>

                        <Modal open={open} onClose={() => setOpen(false)} />


                    </div>


                </motion.div>
            

        </div>

        

    </div>
    )
}

export default ContactUs;