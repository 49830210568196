/* Importing the Assests */

import {
    
    /* Solution */
    IncreaseRevenue,
    EnhanceFacilitySafety,
    MitigateLegalRisk,

    /* Tailgating */
    AccurateTailgatingDetection, 
    CustomerSafety, 
    DifferentiateEntriesAndExits, 

    /* Facial Recognition */
    CatchAccessCardSharing, 
    UpToDateUserImages, 
    SeamlessAccessControl, 

    /* Dashboard */
    RealTimeMonitoring,
    EnhancedTransparency,
    VisualEvidence,
    QuickTraining,

} from '../assets';



/* 
####################################
        About Us Points 
####################################
*/

export const AboutUsPoints = [
    {
        id: "point-1",
        content: "At SecureTeck Inc., we excel in enhancing the security of facilities through advanced video analysis software." 
    },
    {
        id: "point-2",
        content: "Our core mission is to provide facility owners with the critical information necessary to proactively address unauthorized entries."
    },
    {
        id: "point-3",
        content: "This proactive approach equips owners to take effective security measures, safeguarding against legal liabilities and enhancing the safety of all members."
    },
    {
        id: "point-4",
        content: "Our commitment is to ensure a secure environment that adheres to the highest standards of protection, fostering a trustful and secure atmosphere for everyone involved."
    },
];



/* 
####################################
        Our Solution Points 
####################################
*/


export const SolutionPoints = [
    {
        id: "point-1",
        icon: MitigateLegalRisk,
        title: "Mitigate Legal Risk",
        content: "Enhance security compliance with our video analytics that alert you to any potential unauthorized access, supporting proactive measures to mitigate legal risks."
    },
    {
        id: "point-2",
        icon: EnhanceFacilitySafety,
        title: "Enhance Facility Safety",
        content: "Boost the safety of your premises with real-time alerts on tailgating and facial mismatches, allowing for immediate response to safeguard your facility and its occupants."
    },
    {
        id: "point-3",
        icon: IncreaseRevenue,
        title: "Increase Revenue",
        content: "Leverage alerts to identify and convert non-paying visitors into paying members, boosting your revenue."
    },
];



/* 
####################################
        Tailgating Points 
####################################
*/


export const TailgatingPoints = [
    {
        id: "point-1",
        icon: AccurateTailgatingDetection,
        title: "Proactive Tailgating Alerts",
        content: "Stay ahead of security breaches with a system that instantly alerts when tailgating is detected.",
    },
    {
        id: "point-2",
        icon: DifferentiateEntriesAndExits,
        title: "Differentiate Entries and Exits",
        content: "Precisely distinguishes between individuals entering and exiting the facility, minimizing false alarms."
    },
    {
        id: "point-3",
        icon: CustomerSafety,
        title: "Adaptive Lighting",
        content: "Designed to maintain optimal visibility and accurate detection in any lighting condition."
    },
];



/* 
####################################
    Facial Recognition Points 
####################################
*/


export const FacialRecognitionPoints = [
    {
        id: "point-1",
        icon: CatchAccessCardSharing,
        title: "Catch Access Card Sharing",
        content: "Be notified of access card sharing by instantly cross-referencing each entry attempt with the user's profile picture."
    },
    {
        id: "point-2",
        icon: UpToDateUserImages,
        title: "Up-To-Date User Images",
        content: "With each successful match, our software captures additional images, ensuring accuracy and precision."
    },
    {
        id: "point-3",
        icon: SeamlessAccessControl,
        title: "Enhanced Entry Monitoring",
        content: "Add an extra layer of security to existing systems, discreetly monitoring each individual's entry to provide enhanced insight and verification."
    },
];



/* 
####################################
        Dashboard Points 
####################################
*/


/* Left Side */
export const DashboardLeftPoints = [
    {
        id: "point-1",
        icon: RealTimeMonitoring,
        title: "Real-Time Monitoring",
        content: "Comprehensive, real-time surveillance of key access events and immediate alers for any suspicious activity."
    },
    {
        id: "point-2",
        icon: EnhancedTransparency,
        title: "Enhanced Transparency",
        content: "Precise incident details, including key fob IDs, dates, and resolutions."
    },
];

/* Right Side */
export const DashboardRightPoints = [
    {
        id: "point-1",
        icon: VisualEvidence,
        title: "Visual Evidence",
        content: "Quick access to photo and video evidence linked to flagged incidents."
    },
    {
        id: "point-2",
        icon: QuickTraining,
        title: "Quick Training",
        content: "On average, users master our dashboard in just 6 minutes."
    },
];