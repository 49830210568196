/* Tailgating.js */

/* React */
import React from 'react';

/* Animation Framework */
import { motion } from 'framer-motion';
import { fadeIn } from '../Animations/variants';

/* Data */
import { TailgatingPoints } from '../../constants';
import { tailgating } from '../../assets';

/* Tailgating Page */
const Tailgating = () => {
  return (
    <div>

      {/* 
      ################################################################################################
                                          xs: Phones (480px)
      ################################################################################################
      */}
      <div className='flex flex-col sm:hidden items-center w-full h-screen relative'>

        {/* First Portion */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}


          className='relative flex flex-col justify-center items-center h-full top-20'
        >

          {/* Tailgating Text */}
          <div className='w-auto h-16 mb-2 px-4 py-6 ml-2 rounded-full justify-center items-center inline-flex border border-white'>
            <div className='text-blue-gradient text-3xl font-bold font-sans py-1 tracking-wider rounded-full'>
              Tailgating Detection
            </div>
          </div>
          
          

          {/* Tailgating Image */}
          <img className='w-4/5 h-[60vh]' src={tailgating} alt="Tailgating" loading='lazy' />
          
        </motion.div>

        {/* Bottom Portion */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur.
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
          
          className='relative flex items-center justify-center mt-40'
        >

          {/* Black Board */}
          <div className='w-[22rem] h-[28rem] bg-black rounded-3xl border-2 border-teal flex items-center justify-center relative z-10'>

            {/* Content */}
            <ul className='flex flex-col w-full'>

              {/* Map Tailgating Points Array */}
              {TailgatingPoints.map((points) => (
                <li key={points.id} className='flex flex-col items-center py-8 relative'>
                  
                  {/* Top Line */}
                  <div className='flex items-center mb-4 w-[70vw] justify-between'>
                    
                    {/* Icon Container */}
                    <div className='flex-shrink-0'> 
                      <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-gray font-bold font-sans'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-[70vw] text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>
                  
                </li>
              ))}

            </ul>

          </div>
          

        </motion.div>

      </div>
      
      {/* 
      ################################################################################################
                                          sm: Tablets (768px) 
      ################################################################################################
      */}

      <div className='hidden sm:flex md:hidden lg:hidden flex-col items-center'>
        
        {/* First Portion */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}


          className='relative flex flex-col justify-center items-center h-full top-20'
        >

          {/* Tailgating Text */}
          <div className='w-auto h-16 mb-2 px-4 py-6 rounded-full justify-center items-center inline-flex border border-white'>
            <div className='text-blue-gradient text-3xl font-bold font-sans py-1 tracking-wider rounded-full'>
              Tailgating Detection
            </div>
          </div>
          
          

          {/* Tailgating Image */}
          <img className='w-80' src={tailgating} alt="Tailgating" loading='lazy' />
          
        </motion.div>

        {/* Bottom Portion */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur.
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
          
          className='relative flex items-center justify-center mt-40'
        >

          {/* Black Board */}
          <div className='w-[24rem] h-[28rem] bg-black rounded-3xl border-2 border-teal flex items-center justify-center relative z-10'>

            {/* Content */}
            <ul className='flex flex-col w-full'>

              {/* Map Tailgating Points Array */}
              {TailgatingPoints.map((points) => (
                <li key={points.id} className='flex flex-col items-center py-8 relative'>
                  
                  {/* Top Line */}
                  <div className='flex items-center mb-4 w-[18rem] justify-between'>
                    
                    {/* Icon Container */}
                    <div className='flex-shrink-0'> 
                      <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-bold font-sans'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-[18rem] text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>
                  
                </li>
              ))}

            </ul>

          </div>
          

        </motion.div>

      </div>

      {/* 
      ################################################################################################
                                          md: Laptop (1024px) 
      ################################################################################################
      */}

      <div className='hidden md:flex lg:hidden justify-evenly w-full h-screen items-center'>

        {/* Left Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}


          className='relative flex flex-col justify-center items-center h-full mt-14'
        >

          {/* Tailgating Text */}
          <div className='w-auto h-16 p-4 ml-2 rounded-full justify-center items-center inline-flex border border-white'>
            <div className='text-blue-gradient text-2xl font-bold font-sans py-1 tracking-wider rounded-full'>
              Tailgating Detection
            </div>
          </div>
          
          

          {/* Tailgating Image */}
          <img className='w-80 h-[30rem] mt-6' src={tailgating} alt="Tailgating" loading='lazy' />
          
        </motion.div>

        {/* Right Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur.
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
          
          className='relative flex items-center justify-center mt-14'
        >

          {/* Black Board */}
          <div className='w-[24rem] h-[24rem] bg-black rounded-3xl border-4 border-teal flex items-center justify-center relative z-10'>

            {/* Content */}
            <ul className='flex flex-col w-full'>

              {/* Map Tailgating Points Array */}
              {TailgatingPoints.map((points) => (
                <li key={points.id} className='flex flex-col items-center py-6 relative'>
                  
                  {/* Top Line */}
                  <div className='flex items-center mb-2 w-[18rem] justify-between'>
                    
                    {/* Icon Container */}
                    <div className='flex-shrink-0'> 
                      <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-bold font-sans mb-2'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-[18rem] text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>
                  
                </li>
              ))}

            </ul>

          </div>
          

        </motion.div>

      </div>

      
      {/* 
      ################################################################################################
                                          lg: Desktop (1440px) 
      ################################################################################################
      */}

      <div className='hidden lg:flex justify-evenly w-full h-screen items-center'>

        {/* Left Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur
          variants={fadeIn("right", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}


          className='relative flex flex-col justify-center items-start h-full mt-14'
        >

          {/* Tailgating Text */}
          <div className='w-auto h-16 py-4 px-8 rounded-full justify-center items-center inline-flex border border-white'>
            <div className='text-blue-gradient text-3xl font-bold font-sans tracking-wider rounded-full'>
              Tailgating Detection
            </div>
          </div>
          
          

          {/* Tailgating Image */}
          <img className='w-80 h-[30rem] ml-6 mt-2' src={tailgating} alt="Tailgating" loading='lazy' />
          
        </motion.div>

        {/* Right Side */}
        <motion.div 
          // Motion Elements
          // fadeIn(direction, delay)
          // viewport: once: T/F, if animation happens once or always. 
          // viewport: amount: portion of the item on screen for animation to occur.
          variants={fadeIn("left", 0.2)}
          initial= "hidden"
          whileInView={"show"}
          viewport={{once: true, amount: 0.3}}
          
          className='relative flex items-center justify-center mt-14' 
        >

          {/* Black Board */}
          <div className='w-[24rem] h-[24rem] bg-black rounded-3xl border-4 border-teal flex items-center justify-center relative z-10'>

            {/* Content */}
            <ul className='flex flex-col w-full'>

              {/* Map Tailgating Points Array */}
              {TailgatingPoints.map((points) => (
                <li key={points.id} className='flex flex-col items-center py-6 relative'>
                  
                  {/* Top Line */}
                  <div className='flex items-center mb-2 w-[18rem] justify-between'>
                    
                    {/* Icon Container */}
                    <div className='flex-shrink-0'> 
                      <img src={points.icon} alt={points.title} className='w-6 h-6' loading='lazy' />
                    </div>

                    {/* Title Container */}
                    <div className='flex-grow text-center'>
                      <h3 className='text-base text-gray font-bold font-sans'>{points.title}</h3>
                    </div>

                  </div>

                  {/* Bottom Line Content */}
                  <p className='w-[18rem] text-[0.7rem] text-dgray font-sans text-center'>{points.content}</p>
                  
                </li>
              ))}

            </ul>

          </div>
          

        </motion.div>

      </div>

    </div>
  ); 
}

export default Tailgating;